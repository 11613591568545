.expertAnketaQuestionRate {
  display: grid;
  grid-template-columns: auto 200rem;
  grid-template-rows: max-content max-content max-content max-content max-content max-content auto;
  align-items: flex-start;
  padding: 50rem 30rem;
  border-left: 1px solid rgba(0, 0, 0, 0.1);

  .rateTitle {
    grid-column: 1;
    margin-bottom: 45rem;
    font-size: 14rem;
    line-height: 16rem;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 1);
  }

  .rateInstruction {
    grid-column: 2;
    margin: 0 0 45rem;
    padding: 0;
    background-color: unset;
    border: none;

    font-size: 14rem;
    line-height: 16rem;
    text-transform: uppercase;
    color: var(--link-normal);

    cursor: pointer;

    &:hover {
      color: var(--link-hover);
    }
  }

  .rateWrapper {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 16rem 320rem;
    column-gap: 10rem;
    margin-bottom: 30rem;
    cursor: pointer;

    &.seletedRate {
      .indicator {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 3rem;
          top: 3rem;
          width: 8rem;
          height: 8rem;
          background-color: var(--button-blue-normal);
          border-radius: 50%;
        }
      }
    }

    .indicator {
      grid-column: 1;
      grid-row: 1/3;
      width: 16rem;
      height: 16rem;
      border-radius: 50%;
      border: 1rem solid #b6b6b6;
      margin-top: 2.5rem;
      user-select: none;
    }

    .value {
      grid-column: 2;
      grid-row: 1;
      margin-bottom: 5rem;

      font-size: 18rem;
      line-height: 21rem;
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
    }

    .description {
      grid-column: 2;
      grid-row: 2;
      font-size: 14rem;
      line-height: 18rem;
      color: rgba(0, 0, 0, 0.5);

      > p {
        font-size: 14rem;
        line-height: 18rem;
        color: rgba(0, 0, 0, 0.5);
        margin: 0;
      }
    }
  }

  .textareaWYSIWYG {
    grid-column: span 2;
    margin-top: 5rem;
    margin-bottom: 10rem;
  }

  .button {
    grid-column: span 2;
  }
}
